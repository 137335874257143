import { Fragment, useState } from "react"
import { SortOrderEnum } from "enums/OrderEnum"
import { STORAGE_SORT_ORDER, STORAGE_SORT_ORDER_BY_SLOT } from "helpers/constHelper"
import { ISortOrders } from "interfaces/ISortOrders"
import { IconSortAsc, IconSortDesc } from "theme/common/Icon.styled"
import { sortOrderStyled } from "overrides/theme/entel/base/components/Core/Order/Sort/SortOrder.styled"

const SortOrder = ({ handleChangeSort, handleChangeSortSlot, disabled }: ISortOrders) => {
  const storageSortOrder = localStorage.getItem(STORAGE_SORT_ORDER)
  const storageSortOrderSlot = localStorage.getItem(STORAGE_SORT_ORDER_BY_SLOT)
  const [isAscendig, setIsAscendig] = useState(
    !storageSortOrder ? true : JSON.parse(storageSortOrder) === SortOrderEnum.asc,
  )
  const [isAscendigSlot, setIsAscendigSlot] = useState(
    !storageSortOrderSlot ? true : JSON.parse(storageSortOrderSlot) === SortOrderEnum.asc,
  )

  const handleClickSort = () => {
    const sortAscendig = !isAscendig
    setIsAscendig(sortAscendig)
    handleChangeSort(sortAscendig ? SortOrderEnum.asc : SortOrderEnum.desc)
  }

  const handleClickSortSlot = () => {
    const sortAscendigSlot = !isAscendigSlot
    setIsAscendigSlot(sortAscendigSlot)
    handleChangeSortSlot(sortAscendigSlot ? SortOrderEnum.asc : SortOrderEnum.desc)
  }

  return (
    <Fragment>
      <div className="sort-order__main_container">
        <div onClick={handleClickSortSlot} className="sort-order__container">
          <>
            <p> Ordenar por Slot </p>
            {isAscendigSlot ? <IconSortAsc /> : <IconSortDesc />}
          </>
        </div>
        <div onClick={handleClickSort} className="sort-order__container">
          {isAscendig ? (
            <>
              <p> Ver las más recientes </p>
              <IconSortAsc />
            </>
          ) : (
            <>
              <p> Ver las más antiguas</p>
              <IconSortDesc />
            </>
          )}
        </div>
      </div>
      <style jsx>{sortOrderStyled}</style>
    </Fragment>
  )
}
export default SortOrder
