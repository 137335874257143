import { Fragment, useContext, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { GlobalContext } from "context/context/GlobalContext"
import {
  headerStyle,
  buttonIconPopUpStyle,
} from "overrides/theme/entel/base/components/Theme/Layout/Main/styles/Header.style"
import { AuthContext } from "context/context/AuthContext"
import useComponentVisible from "hooks/useComponentVisible"

import logo_entel from "assets/images/logo_entel.svg"
// icons
import user_icon_profile from "assets/icons/entel/user_icon_alt.svg"
// import edit_icon from "assets/icons/entel/edit-icon.svg"
import store_icon from "assets/icons/store.svg"
import logout_icon from "assets/icons/entel/logout-icon.svg"
import logo_omnix from "assets/images/logo-omnix3.png"
import useAuthorizer from "overrides/theme/entel/base/hooks/v4/useAuthorizer"
import { FaShippingFast } from "react-icons/fa"

export const Header = () => {
  const { logout } = useAuthorizer()
  const authState = useContext(AuthContext)
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const user = authState?.state.user
  const [userMenuPopupRef, userMenuShowPopup, setUserMenuShowPopup] = useComponentVisible(false)
  const signOut = () => logout()
  const location = useLocation()

  useEffect(() => {
    setUserMenuShowPopup(false)
    //eslint-disable-next-line
  }, [location])

  const ButtonIconPopUp = ({ icon: Icon, text, route, onClick, isIcon }: any) => (
    <Fragment>
      {onClick ? (
        <span className="header__cont-button" onClick={onClick}>
          {isIcon ? <Icon className="header__cont-button-icon" /> : <img src={Icon} alt={text} />}
          <p>{text}</p>
        </span>
      ) : (
        <Link className="header__cont-button" to={route}>
          {isIcon ? <Icon className="header__cont-button-icon" /> : <img src={Icon} alt={text} />}
          <p>{text}</p>
        </Link>
      )}
      <style jsx>{buttonIconPopUpStyle}</style>
    </Fragment>
  )

  return (
    <Fragment>
      <div className="header__cont-header">
        <div className="header__cont-logo-and-title">
          <a href="/">
            <img src={logo_entel} alt="logo" className="header__client-logo" />
          </a>
          <h3>{displayHeaderRouteName.state}</h3>
        </div>
        <div className="header__cont-logo-notification-and-user">
          <img src={logo_omnix} alt="logo" className="logo_omnix" />
          <div className="header__cont-notification-and-user">
            <div className="header__dropdown-user">
              <div className="header__user-info header__user-info-in-button">
                <p className="header__name">{user?.name}</p>
                <p className="header__role">{user?.role}</p>
              </div>
              <img
                src={user_icon_profile}
                alt="X"
                className="header__profile-icon"
                onClick={() => {
                  setUserMenuShowPopup(!userMenuShowPopup)
                  // console.log("Hola", userMenuShowPopup)
                }}
              />
            </div>
          </div>
        </div>
        <div
          ref={userMenuPopupRef}
          className={!userMenuShowPopup ? "header__dropdown-menu" : "header__dropdown-menu header__box--show"}
        >
          <div className="header__dropdown-menu__body">
            <Link to={`/users/${user?.id}`} className="header__cont-user-info__popup">
              <img src={user_icon_profile} alt="X" className="header__profile-icon-popup" />
              <div className="header__user-info">
                <p className="header__name">{user?.name}</p>
                <p className="header__role">{user?.role}</p>
              </div>
            </Link>
            <div className="header__dropdown-menu__secondary_text_cont">
              <p className="header__dropdown-menu__secondary_text">userName: {user?.custom?.userName}</p>
              <p className="header__dropdown-menu__secondary_text">jobPosition: {user?.custom?.jobPosition}</p>
              <p className="header__dropdown-menu__secondary_text">marketSegment: {user?.custom?.marketSegment}</p>
              <p className="header__dropdown-menu__secondary_text">stores: {user?.custom?.stores?.join(", ")}</p>
            </div>
            <div className="header__cont-buttons">
              {/* <ButtonIconPopUp text="Editar Perfil" icon={edit_icon} route={`/users/edit/${user?.id}`} /> */}
              <ButtonIconPopUp text="Administrar tiendas/bodegas" icon={store_icon} route="/manage_sources" />
              <ButtonIconPopUp text="Control métodos de envío" icon={FaShippingFast} isIcon route="/manage_sources/deliveries" />
              <ButtonIconPopUp text="Cerrar Sesión" icon={logout_icon} onClick={signOut} />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{headerStyle}</style>
    </Fragment>
  )
}

export default Header
