import DashBordScreen from "overrides/theme/entel/base/pages/HomeScreens/HomeScreen/DashBoardScreen"
import OrderScreen from "overrides/theme/entel/base/pages/OrderScreens/OrderScreen"
import ProfileScreen from "pages/UserScreens/ProfileScreen/ProfileScreen"
import ErrorHandler from "components/Commons/Error/ErrorHandler"
import { IRouteComponent } from "interfaces/IRoute"
import SearchResultsScreen from "overrides/theme/entel/base/pages/SearchResults/SearchResultsScreen"
import OrderDetailScreen from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetailScreen"
import BulkLoadScreen from "overrides/theme/entel/base/pages/BulkLoadScreen/BulkLoadScreen"
import ChartsScreen from "overrides/theme/entel/base/pages/ChartsScreen/ChartsScreen"
import AdminSourcesScreen from "overrides/theme/entel/base/pages/AdminSourcesScreen/AdminSourcesScreen"
import ListUsersScreen from "overrides/theme/entel/base/pages/UsersScreens/ListUsersScreen"
import NewUserScreen from "overrides/theme/entel/base/pages/UsersScreens/NewUserScreen"
import DetailUserScreen from "overrides/theme/entel/base/pages/UsersScreens/DetailUserScreen"
import ReportScreen from "overrides/theme/entel/base/pages/ReportScreens/ReportScreen"
import AdminDeliveriesSourcesScreen from "overrides/theme/entel/base/pages/AdminDeliveriesSourcesScreen/AdminDeliveriesSourcesScreen"

const RouteList: IRouteComponent[] = [
  {
    path: "/",
    name: "DashBordScreen",
    component: DashBordScreen,
    exact: true,
  },
  {
    path: "/dashboard",
    name: "DashBordScreen",
    component: DashBordScreen,
    exact: true,
  },
  {
    path: "/profile",
    name: "ProfileScreen",
    component: ProfileScreen,
    exact: true,
  },
  {
    path: "/users",
    name: "ListUsersScreen",
    component: ListUsersScreen,
    exact: true,
  },
  {
    path: "/users/new",
    name: "NewUserScreen",
    component: NewUserScreen,
    exact: true,
  },
  {
    path: "/users/edit/:userId",
    name: "EditUserScreen",
    component: NewUserScreen,
    exact: true,
  },
  {
    path: "/users/:userId",
    name: "DetailUserScreen",
    component: DetailUserScreen,
    exact: true,
  },
  {
    path: ["/search/users/:query"],
    name: "ListUsersScreenSearch",
    component: ListUsersScreen,
    exact: true,
  },
  {
    path: ["/search/:optionFilter/:query"],
    name: "SearchResultsPage",
    component: SearchResultsScreen,
    exact: true,
  },
  {
    path: ["/order/:shippingtype/:type", "/search/order/:shippingtype/:type/:optionFilter/:query"],
    name: "OrderPage",
    component: OrderScreen,
    exact: true,
  },
  {
    path: ["/order/:shippingtype/:shippingGroupId/:id"],
    name: "OrderDetailScreen",
    component: OrderDetailScreen,
    exact: true,
  },
  {
    path: "/report",
    name: "ReportPage",
    component: ReportScreen,
    exact: true,
  },
  {
    path: "/bulk",
    name: "BulkLoadScreen",
    component: BulkLoadScreen,
    exact: true,
  },
  {
    path: "/manage_sources",
    name: "AdminSourcesScreen",
    component: AdminSourcesScreen,
    exact: true,
  },
  {
    path: "/manage_sources/deliveries",
    name: "AdminDeliveriesSourcesScreen",
    component: AdminDeliveriesSourcesScreen,
    exact: true,
  },
  {
    path: "/charts",
    name: "ChartsScreen",
    component: ChartsScreen,
    exact: true,
  },
  {
    path: "*",
    name: "notFound",
    component: ErrorHandler,
    exact: false,
  },
]

export default RouteList
